export const ROUTES = {
  Signup: "/signup",
  Login: "/login",
  ForgotPassword: "/forgotpassword",
  Sidebar: "/",
  Dashboard: "/dashboard",
  Home: "/home",

  /*Adim Module Starts------------------------ */
  /*User SubModule Starts */
  User: "/admin/user",
  AddUser: "/admin/user/addUser",
  EditUser: "admin/user/editUser/:id",
  /*User SubModule Ends */

  /*Company SubModule Starts */
  CompanyManagement: "/admin/companyManagement",
  AddCompany: "/admin/companyManagement/addCompany",
  EditCompany: "/admin/companyManagement/editCompany/:id",
  /*Company SubModule Ends */

  /*Branch SubModule Starts */
  Branch: "/admin/branch",
  AddBranch: "/admin/branch/addBranch",
  EditBranch: "/admin/branch/editBranch/:id",
  /*Branch SubModule Ends */

  /*Godown/Showroom SubModule Starts */
  GodownShowroom: "/admin/godownOrShowroom",
  AddGodownShowroom: "/admin/godownOrShowroom/AddGodownOrShowroom",
  EditGodownShowroom: "/admin/godownOrShowroom/EditGodownOrShowroom/:id",
  /*Godown/Showroom SubModule Ends */

  /*StockLocation SubModule Starts */
  StockLocation: "/admin/stockLocation",
  AddStockLocation: "/admin/stockLocation/addStockLocation",
  EditStockLocation: "/admin/stockLocation/editStockLocation/:id",
  /*StockLocation SubModule Ends */

  /*AccessControl SubModule Starts */
  AccessControl: "/admin/accessControl",
  EditAccess: "/admin/accessControl/editAccess",
  /*AccessControl SubModule Ends */

  /* price updates SubModule Starts */
  PriceUpdate: "/admin/priceUpdate",
  /* price updates SubModule Ends */

  /*Admin Module Ends------------------------------ */

  /*Inventory Module Starts------------------------------ */
  Inventory: "/inventory",

  /*Item SubModule Starts */
  Item: "/inventory/item",
  AddItem: "/inventory/item/addItem",
  EditItem: "/inventory/item/editItem/:id",
  /*Item SubModule Ends */

  /*Category SubModule Starts */
  Category: "/inventory/category",
  Brand: "/inventory/brand",
  Series: "/inventory/series",
  SuggestedItem: "/inventory/suggestedItem",
  AddSuggestedItem: "/inventory/suggestedItem/addSuggestedItem",
  EditSuggestedItem: "/inventory/suggestedItem/editSuggestedItem/:id",
  /*Category SubModule Starts */

  /*ManageStock SubModule Starts */
  ManageStock: "/inventory/manageStock",
  ReceiveGoods: "/inventory/manageStock/receiveGoods",
  IssueGoods: "/inventory/manageStock/issueGoods",
  /*ManageStock SubModule Ends */
  /*Inventory Module Ends------------------------------ */

  Accounts: "/accounts",
  Sales: "/sales",
  Procurement: "/procurement",
  Payments: "/payments",
  Tax: "/tax",

  // Employee management module start..
  Employeemanagement: "/employeemanagement",
  // Employee management module end...

  // Employee management submudule start...
  Employee: "/employeemanagement/employee",
  AddEmployee: "/employeemanagement/employee/addEmployee",
  EditEmployee: "/employeemanagement/employee/editEmployee/:id",
  // Employee management submodule end..

  //purchases module start
  Vendor: "/purchase/vendor",
  AddVendor: "purchase/vendor/addVendor",
  EditVendor: "purchase/vendor/editVendor/:id",

  PurchaseOrder: "/purchase/purchaseorder",
  AddPurchaseOrder: "purchase/purchaseorder/addPurchaseOrder",
  EditPurchaseOrder: "purchase/purchaseorder/editPurchaseOrder/:id",
  ViewPurchaseOrder: "purchase/purchaseorder/viewPurchaseOrder/:id",

  PurchaseBills: "/purchase/purchaseBills",
  AddPurchaseBills: "/purchase/purchaseBills/addPurchaseBills",
  EditPurchaseBills: "/purchase/purchaseBills/editPurchaseBills/:id",
  ViewPurchaseBills: "/purchase/purchaseBills/viewPurchaseBills/:id",

  PurchaseTarget: "/purchase/purchaseTarget",
  AddPurchaseTarget: "/purchase/purchaseTarget/addPurchaseTarget",
  EditPurchaseTarget: "/purchase/purchaseTarget/editPurchaseTarget/:id",

  PurchasePayment: "/purchase/purchasePayment",
  AddPurchasePayment: "/purchase/purchasePayment/addPurchasePayment",
  //purchases module end

  //accounting module start
  ManualJournal: "accounting/manualJournal",
  AddManualJournal: "accounting/manualJournal/newJournal",
  EditManualJournal: "accounting/manualJournal/editJournal/:id",

  ChartOfAccount: "/accounting/chartOfAccount",

  Ledger: "accounting/ledger",
  OtherPayment: "accounting/otherPayment",

  //accounting module end

  /*----------------Sales module start---------------------*/
  /*Customer Registration SubModule Starts */
  Customers: "/sales/customer",
  AddCustomers: "/sales/customer/addCustomer",
  EditCustomers: "/sales/customer/editCustomer/:id",
  /*Customer Registration SubModule Starts */

  /*Project SubModule Starts */
  ProjectManagement: "/sales/projectManagement",
  AddProjectManagement: "/sales/projectManagement/addProjectManagement",
  EditProjectManagement: "/sales/projectManagement/editProjectManagement/:id",
  /*Project SubModule Ends */

  /*Quotation SubModule Starts */
  Quotation: "/sales/quotation",
  AddQuotation: "/sales/quotation/addQuotation",
  EditQuotation: "/sales/quotation/editQuotation/:id",
  ViewQuotation: "/sales/quotation/viewQuotation/:id",
  /*Quotation SubModule Ends */

  //------------Sales Target SubModule Starts --------------------
  SalesTarget: "/sales/salesTarget",
  AddSalesTarget: "/sales/salesTarget/addSalesTarget",
  EditSalesTarget: "/sales/salesTarget/editSalesTarget/:id",

  DeliveryReceipt: "/sales/deliveryReceipt",
  DeliveryReceiptAddSignature: "/deliveryReceipt/signatureRequired/:id",

  //-----------Sales Target SubModule Ends ----------------------

  //------------Sales Order SubModule Starts --------------------
  SalesOrder: "/sales/salesOrder",
  AddSalesOrder: "/sales/salesOrder/addSalesOrder",
  EditSalesOrder: "/sales/salesOrder/editSalesOrder/:id",
  ViewSalesOrder: "/sales/salesOrder/viewSalesOrder/:id",
  AddCustomersInSalesOrder: "/sales/salesOrder/addCustomer",

  //-----------Sales Order SubModule Ends ----------------------

  //------------Sales Invoice SubModule Starts --------------------
  SalesInvoice: "/sales/salesInvoice",
  AddSalesInvoice: "/sales/salesInvoice/addSalesInvoice",
  EditSalesInvoice: "/sales/salesInvoice/editSalesInvoice/:id",
  ViewSalesInvoice: "/sales/salesInvoice/viewSalesInvoice/:id",
  AddCustomersInSalesInvoice: "/sales/salesInvoice/addCustomer",
  //-----------Sales Invoice SubModule Ends ----------------------

  //------------Sales Payment SubModule Starts --------------------
  SalePayment: "/sales/salePayment",
  AddSalePayment: "/sales/salePayment/addSalePayment",
  ViewSalePayment: "/sales/salePayment/viewSalePayment/:id",
  //-----------Sales Invoice SubModule Ends ----------------------

  //------------Sales Invoice SubModule Starts --------------------
  CreditNote: "/sales/creditNote",
  AddCreditNote: "/sales/creditNote/addCreditNote",
  EditCreditNote: "/sales/creditNote/editCreditNote/:id",
  ViewCreditNote: "/sales/creditNote/viewCreditNote/:id",
  //-----------Sales Invoice SubModule Ends ----------------------

  /*----------------Sales module end---------------------*/

  // projects start

  // ProjectManagement: "projects/projectManagement",
  // AddProjectManagement: "projects/projectManagement/addProjectManagement",
  // EditProjectManagement: "projects/projectManagement/editProjectManagement/:id",

  // ProjectTeams:"projects/projectTeams",
  Targets: "/targets/targetsDashboard",

  //projects end

  //taxes start

  Tax: "/taxes/tdsTcs",
  AddTcs: "/taxes/tdsTcs/addTcs",
  EditTcs: "/taxes/tdsTcs/editTcs/:id",
  AddTds: "/taxes/tdsTcs/addTds",
  EditTds: "/taxes/tdsTcs/editTds/:id",

  Vat: "/taxes/vat",
  AddVat: "/taxes/vat/addVat",
  EditVat: "/taxes/vat/editVat/:id",
  //taxes end

  //Banking module start

  Bank: "banking/bank",
  AddBank: "banking/addBank",
  EditBank: "banking/editBank/:id",

  //  BankStatement:"banking/bankStatement",

  Transaction: "banking/transaction",

  signaturepad: "signaturepad",
  //  CashInHand:"banking/cashInHand"
  //Banking module end

  //Report Start

  SellReportsCommission: "/reports/sellReportCommission",
  ItemAgeReport: "/reports/itemAgeReport",

  //Report End

  AuditLog: "/auditLog"
};
