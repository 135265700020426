import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Spinner,
} from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../../../constants/toastAlert";
import { addTcs, tcsData } from "../../../redux/actions/tcs.actions";
import { tcsIdAutocountApi } from "../../../constants/api";
import axios from "axios";
import { getCurrentPreviousYears } from "../../../constants/currentPreviousYear";

function AddVat() {
    const URL = extractAndFormatURLPart("taxes");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const loadData = useSelector((state) => state.loadReducer);
    const { companyid, userid, companyname, branch, branchid, org } =
        useSelector((state) => state.authManager.userData.data[0]) || {};
    const TCSID = useSelector((state) => state.tcsData.tcsData.data) || [];
    const [showEmptyError, setShowEmptyError] = useState(false);
    const navigate = useNavigate();

    const [data, setData] = useState({
        vatno: "",
        section: "",
        ratepercent: "",
        taxname: "",
        applicabledate: "",
        org: org,
        companyid: companyid,
        userid: userid,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const backendData = {
        companyid: companyid,
        userid: userid,
        branchid: branchid,
        keyword: "" || "₹ΘθϖΠ",
    };

    useEffect(() => {
        setLoading(!loading);
        dispatch(tcsData(backendData, setLoading));
    }, [dispatch]);

    const generateCategoryID = () => {
        if (TCSID.length === 0) {
            // If no data, default to 1
            return "1";
        } else {
            // Get the last category ID
            const lastCategoryID = TCSID[0].tcsno.split("/")[2];
            // Increment the last category ID and return
            return String(parseInt(lastCategoryID) + 1);
        }
    };

    useEffect(() => {
        // Update the category ID in the row state
        let count = generateCategoryID();
        let paddedCount = count.toString().padStart(4, "0");
        setData((prevRow) => ({
            ...prevRow,
            vatno: `VAT/${getCurrentPreviousYears()}/${paddedCount}`,
        }));
    }, []);

    const handleSave = () => {

        if (!data.applicabledate || !data.ratepercent || !data.section || !data.taxname || !data.vatno) {
            setShowEmptyError(true);
            notifyError("You have empty fields");
            return;
        }

        console.log("data", data);
        // dispatch(addTcs(data, navigate));

    };

    return (
        <Container fluid>
            <Row>
                <Col className="colStyle p-0">
                    <span>{URL}</span>
                </Col>
            </Row>
            <div className="form-container">
                <Form className="form-lable-name">
                    <h1>
                        <FaArrowLeftLong
                            className="left-arrow"
                            onClick={() => navigate(-1)}
                        />
                        Add <span className="form-title main"> VAT </span>
                    </h1>
                    <hr />
                    <div className="d-flex gap-4">
                        <div className="w-100">
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>
                                                        VAT No.<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        name="vatno"
                                                        value={data.vatno}
                                                        //   onChange={handleChange}
                                                        isInvalid={!data.vatno && showEmptyError}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>
                                                        Rate Percent<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="ratepercent"
                                                        value={data.ratepercent}
                                                        onChange={handleChange}
                                                        isInvalid={!data.ratepercent && showEmptyError}
                                                        min={"0"}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>
                                                Section<span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="section"
                                                value={data.section}
                                                onChange={handleChange}
                                                isInvalid={!data.section && showEmptyError}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>
                                                        Tax Name<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="taxname"
                                                        value={data.taxname}
                                                        onChange={handleChange}
                                                        isInvalid={!data.taxname && showEmptyError}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label>
                                                        Applicable Date<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="applicabledate"
                                                        value={data.applicabledate}
                                                        onChange={handleChange}
                                                        isInvalid={!data.applicabledate && showEmptyError}
                                                    />

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </Form>
                <hr />
                <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
                    <Button className="commonBtn smallBtn" onClick={handleSave}>
                        {loadData ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            "Save"
                        )}
                    </Button>
                    <Button
                        className="commonBtn smallBtn cancelBtn"
                        onClick={() => navigate("/taxes/vat")}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </div>
        </Container>
    )
}

export default AddVat