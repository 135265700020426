import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchField from '../../../constants/SearchField';
import DataSpinner from '../../../constants/DataSpinner';
import { MdModeEditOutline } from 'react-icons/md';
import DeleteConfirmation from '../../../constants/DeleteConfirmation';
import NoRecordFoud from '../../../constants/NoRecordFoud';
import GlobalPagination from '../../../constants/GlobalPagination';

function ListTableVat() {
    const navigate = useNavigate();
    //state
    const [searchKeyword, setSearchKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectedItemNo, setSelectedItemNo] = useState(null);

    //Redux part
    const dispatch = useDispatch();
    const { companyid, userid, companyname, branchid, org } =
        useSelector((state) => state.authManager.userData.data[0]) || {};
    const data = useSelector((state) => state.tcsData.tcsData);
    const loadData = useSelector((state) => state.loadReducer);
    const filteredData = !data.data ? [] : data.data;

    //pagination data
    const totalPagesFromAPI = {
        totalRecords: data.totalItems ? data.totalItems : 0,
        totalPages: data.totalPages ? data.totalPages : 0,
    };

    //Default data backend needed
    const backendData = {
        keyword: searchKeyword || "₹ΘθϖΠ",
        companyid: companyid,
        userid: userid,
        branchid: branchid,
        page: currentPage,
        pageSize: pageSize,
    };

    useEffect(() => {
        // setLoading(!loading);
        // dispatch(tcsData(backendData, setLoading));
    }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

    const handleShow = (item) => {
        const { id, tcsno } = item;
        setShow(true);
        setSelectedItemId(id);
        setSelectedItemNo(tcsno);
    };

    const handleEdit = (id) => {
        navigate(`/taxes/vat/editVat/${id}`);
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleDelete = () => {
        // dispatch(
        //   deleteTcsData(
        //     {
        //       id: selectedItemId,
        //       tcsno: selectedItemNo,
        //       companyid: companyid,
        //       userid: userid,
        //       org: org,
        //     },
        //     handleClose
        //   )
        // );
    };

    return (
        <Row style={{ marginLeft: "0rem" }}>
            <Col className="table-wrapper-container">
                <Row>
                    <Col lg={8}>
                        <p className="section-title form-section ptag">
                            List of All <span className="form-title">VAT</span>
                        </p>
                    </Col>
                    <Col lg={2}>
                        <SearchField
                            searchKeyword={searchKeyword}
                            setSearchKeyword={setSearchKeyword}
                        />
                    </Col>
                    <Col lg={2}>
                        <Button
                            className="commonBtn mt-2"
                            onClick={() => navigate("/taxes/vat/addVat")}
                        >
                            Add VAT
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col md={12} className="table-main-container">
                <Table responsive>
                    <thead className="tableContainer">
                        <tr>
                            <th>VAT NO.</th>
                            <th>Section</th>
                            <th>Rate %</th>
                            <th>Tax Name</th>
                            <th>Applicable Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loadData || loading ? (
                            <>
                                <DataSpinner count="6" />
                            </>
                        ) : filteredData.length > 0 ? (
                            filteredData.map((item, index) => {
                                const {
                                    vatno,
                                    section,
                                    ratepercent,
                                    id,
                                    taxname,
                                    applicabledate,
                                } = item;
                                return (
                                    <tr key={index}>
                                        <td>{vatno ? vatno : "--"}</td>
                                        <td>{section ? section : "--"}</td>
                                        <td>{ratepercent ? ratepercent : "--"}</td>
                                        <td>{taxname ? taxname : "--"}</td>
                                        <td>{applicabledate ? applicabledate : "--"}</td>
                                        <td className="d-flex justify-content-center gap-2">
                                            <div>
                                                <MdModeEditOutline
                                                    className="editIcon mr-2"
                                                    onClick={() => handleEdit(item.id)}
                                                />{" "}
                                                &nbsp;
                                                <DeleteConfirmation
                                                    show={show}
                                                    handleShow={() => handleShow(item)}
                                                    handleClose={handleClose}
                                                    handleDelete={handleDelete}
                                                    name={"TCS"}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <NoRecordFoud count="6" name="VAT" />
                        )}
                    </tbody>
                </Table>
            </Col>
            {/* pagination ui  */}
            <GlobalPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPagesFromAPI={totalPagesFromAPI}
                setPageSize={setPageSize}
            />
        </Row>
    )
}

export default ListTableVat