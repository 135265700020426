import React, { useState } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import ListTableVat from './ListTableVat';

function Vat() {
    const [currentTab, setCurrentTab] = useState("VAT");
    const URL = extractAndFormatURLPart("taxes");

    // const handleTabSelect = (key) => {
    //     // Define the extracted part based on the selected tab event key
    //     switch (key) {
    //         case "tcs":
    //             setCurrentTab("TCS");
    //             break;
    //         //   case "roletransition":
    //         //     setCurrentTab("Role Transition");
    //         //     break;
    //         default:
    //             setCurrentTab("TDS");
    //     }
    // };

    return (
        <Container fluid>
            <Row >
                <Col className="colStyle">
                    <span>{URL + "/ " + currentTab}</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs
                        defaultActiveKey="vat"
                        id="justify-tab-example"
                        className="mb-3"
                    //variant='pills'
                    //fill
                    // onSelect={handleTabSelect}
                    >
                        <Tab eventKey="vat" title="VAT" className="tabview">
                            <ListTableVat />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    )
}

export default Vat